import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import NoteIcon from '@material-ui/icons/Note';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
}));

function ListItemLink(props) {
	return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<List component="nav" aria-label="main mailbox folders">
				<ListItemLink button href="#login">
					<ListItemIcon>
						<span className="material-icons"><LockOpenIcon /></span>
					</ListItemIcon>
					<ListItemText primary="Log in" />
				</ListItemLink>
				<ListItemLink button href="#location">
					<ListItemIcon>
						<span className="material-icons"><MyLocationIcon /></span>
					</ListItemIcon>
					<ListItemText primary="Location" />
				</ListItemLink>
				<ListItemLink button href="#selfie">
					<ListItemIcon>
						<span className="material-icons"><AddAPhotoIcon /></span>
					</ListItemIcon>
					<ListItemText primary="Selfie" />
				</ListItemLink>
				<ListItemLink button href="#logs">
					<ListItemIcon>
						<span className="material-icons"><NoteIcon /></span>
					</ListItemIcon>
					<ListItemText primary="Your logs" />
				</ListItemLink>
			</List>
		</div>
	);
}
