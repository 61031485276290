import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink button href="#pw-units">
          <ListItemIcon>
          <span className="material-icons">
            attach_money
          </span>
          </ListItemIcon>
          <ListItemText primary="Payment units" />
        </ListItemLink>
        <ListItemLink button href="#pw-templates">
          <ListItemIcon>
            <span className="material-icons">list_alt</span>
          </ListItemIcon>
          <ListItemText primary="Managing templates" />
        </ListItemLink>
        <ListItemLink button href="#pw-add">
          <ListItemIcon>
            <span className="material-icons">
              note_add
            </span>
          </ListItemIcon>
          <ListItemText primary="Adding price works" />
        </ListItemLink>
        <ListItemLink button href="#pw-manage">
          <ListItemIcon>
            <span className="material-icons">
              preview
            </span>
          </ListItemIcon>
          <ListItemText primary="Managing works" />
        </ListItemLink>
      </List>
    </div>
  );
}
