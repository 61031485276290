import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ossImg13 from "../../../../media/help-section/oss/ossscreen13.png"
import ossImg14 from "../../../../media/help-section/oss/ossscreen14.png"
import ossImg15 from "../../../../media/help-section/oss/ossscreen15.png"

const TimesheetBuilderContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="timesheet-builder-instruction">Timesheet Builder</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Timesheet Builder Functionalities</b>
                        </h3>

                            In order to access the <strong>timesheet builder</strong> section, please navigate to the "Timesheet Builder" tab in the main menu. From there, you can preview entries of individual users, week by week. <br></br>
                            Once you enter the page, please <strong>choose an employee from the drop down list.</strong> Once you choose the employee, a second drop-down list will appear. From there, you can choose the week from which you want to check the given user's entries. Once the week is chosen, the following table should appear: 
                            <br></br><br></br>
                            <img alt="screen1" src={ossImg13}></img>
                            <br></br><br></br>
                            In the table, you can preview user's entries for each day, from the whole week, including their:
                            <ul>
                                <li>Clock-in and Clock-out times;</li>
                                <li>Rounded clock-in and clock-out times (depending on your company's settings); </li>
                                <li>Total hours from each day;</li>
                                <li>Total hours from the whole week;</li>
                                <li>Their clock-in and clock-out locations;</li>
                                <li>Their selfies;</li>
                                <li>Eventual comments *;</li>
                                <li>Their score **.</li>
                            </ul>
                            * - For each entry, you can add an additional comment which is going to be visible to you and all the other administrators of the systems from within your company. To do that, simply press the "add" link. A pop-up window will then be displayed, where you will be able to enter your note. Once you do it, a fragment note will appear in the table. To preview the whole comment or update it, press on its text.<br></br>
                            ** - The system automatically generated score based on the user's hours. The scores are described in greater detail in the section below.
                            <br></br><br></br>
                            The matched project can be adjusted. In order to do that, please press on the project's label in the table.<br></br>
                            Working hours of the employee can also be adjusted. The details are described in the third section of this instructions regarding Timesheet Builder/
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="scores">Timesheet's Scores</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Automated timesheer's scores</b>
                        </h3>

                            Each employee's entry in the timesheet builder gets an automated score value, based on the number of hours for which they had worked. The score is displayed in the last column of the timesheet builder. Possible scores, and their assigned background colours are listed below:
                            <ul>
                                <li>OK - green colour - when an employee worked for the right amount of hours;</li>
                                <li>Data Missing - black - when an employee did not clock-in or out;</li>
                                <li>Warning - orange</li>
                                <li>Needs attention - red</li>
                            </ul>
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="adjusting-times">Adjusting employee's hours</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Changing actual hours</b>
                        </h3>

                        If you wish to change the actual time at which an employee clocked in/out, please press on the chosen value from the "actual" column. Once it is done, the following pop-up box should appear on the page:
                        <br></br><br></br>
                        <img alt="screen2" src={ossImg14} className={styles.helpImgMd}></img>
                        <br></br><br></br>
                        Please select the hour from the drop-down list, and press the "Amend Timesheet" button to finish.
                    </div>
                </div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Changing billable hours</b>
                        </h3>

                        If you wish to change the billable hour at which an employee clocked in/out, please press on the chosen value from the "billable" column. Once it is done, the following pop-up box should appear on the page:
                        <br></br><br></br>
                        <img alt="screen3" src={ossImg15} className={styles.helpImgMd}></img>
                        <br></br><br></br>
                        Please select the hours from the drop-down list, and press the "Save" button to finish.<br></br>
                        You can also apply these changes for the whole week, by checking the "Apply to the whole week?" checkbox.
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default TimesheetBuilderContent;