import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink button href="#qa-templates">
          <ListItemIcon>
          <span className="material-icons">
          developer_board
          </span>
          </ListItemIcon>
          <ListItemText primary="Templates" />
        </ListItemLink>
        <ListItemLink button href="#qa-adding">
          <ListItemIcon>
            <span className="material-icons">control_point</span>
          </ListItemIcon>
          <ListItemText primary="Adding QAs" />
        </ListItemLink>
        <ListItemLink button href="#qa-managing">
          <ListItemIcon>
            <span className="material-icons">
            list
            </span>
          </ListItemIcon>
          <ListItemText primary="Management" />
        </ListItemLink>
        <ListItemLink button href="#qa-testing">
          <ListItemIcon>
            <span className="material-icons">
            assessment
            </span>
          </ListItemIcon>
          <ListItemText primary="Pressure Tests" />
        </ListItemLink>
      </List>
    </div>
  );
}
