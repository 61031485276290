import React from 'react';
import styles from "../../styles/help-page/SystemHelp.module.css";

const ComponentHelpDetails = props => {
    return (
        <div className={styles.instructionWithPointers}>
            <div className={styles.instructionScrollList}>
                {props.scrollList}
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default ComponentHelpDetails;