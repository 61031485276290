import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Projects
import ProjectsStepper from './Projects/ProjectsStepper';
import ProjectsScrollList from './Projects/ProjectsScrollList';
import ProjectsContent from './Projects/ProjectsContent';


const ProjectsPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="manage-projects"
                title="Managing Projects"
                video={<HelpVideo link="https://www.youtube.com/embed/Tml2JiORcuc" title="OSS - Projects Tutorial" />}
                stepper={<ProjectsStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<ProjectsScrollList />} 
                >
                    <InstructionScrollField 
                        title="Managing Projects"
                    >
                        <ProjectsContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default ProjectsPage;
