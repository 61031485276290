import React from 'react';
import RavMenuTabs from './RavMenuTabs';

const RavHelp = () => {
    return(
        <div className="ravHelp">
            <RavMenuTabs />
        </div>
    );
};

export default RavHelp;