import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import SliderWithVideo from '../SliderWithVideo';


const ApproveSM = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="hub-projects"
                title="CMS Hub - Approval as Site Manager"
                video={<HelpVideo link="https://www.youtube.com/embed/rkVvgM1jkMc" title="CMS Hub - Approval as Site Manager" />}
            >
                
            </SliderWithVideo>
        </div>
    );
};

export default ApproveSM;
