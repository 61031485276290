import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../styles/help-page/SystemHelp.module.css';
import RavMenuTab from './RavMenuTab';
import { PersonCircle, CardHeading, PersonSquare, FileEarmarkDiff, ShieldCheck, Cash, FileEarmarkSpreadsheet, PersonCheck } from 'react-bootstrap-icons';

const RavMenuTabs = () => {
    return(
        <div className={styles.helpMenuBox}>
            <div className={styles.helpMenuTabList}>
                <Link to="/rav/users"><RavMenuTab tabText="System Users" tabIcon={<PersonCircle/>} /></Link>
                <Link to="/rav/projects"><RavMenuTab tabText="Projects" tabIcon={<CardHeading />} /></Link>
                <Link to="/rav/subcontractors"><RavMenuTab tabText="Subcontractors" tabIcon={<PersonSquare />} /></Link>
            </div>
            <div className={styles.helpMenuTabList}>
                <Link to="/rav/price-works"><RavMenuTab tabText="Price Works" tabIcon={<FileEarmarkDiff/>} /></Link>
                <Link to="/rav/qa"><RavMenuTab tabText="Quality Assessment" tabIcon={<ShieldCheck/>} /></Link>
                <Link to="/rav/vouchers"><RavMenuTab tabText="Payment Vouchers" tabIcon={<Cash/>} /></Link>
            </div>
            <div className={styles.helpMenuTabList}>
                <Link to="/rav/documents"><RavMenuTab tabText="Documents" tabIcon={<FileEarmarkSpreadsheet/>} /></Link>
                <Link to="/rav/cc"><RavMenuTab tabText="Customer Care" tabIcon={<PersonCheck/>} /></Link>
                <Link to="/rav" className={styles.hh1}><RavMenuTab /></Link>
            </div>
        </div>
        
    );
};

export default RavMenuTabs;