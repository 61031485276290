import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import SliderWithVideo from '../SliderWithVideo';


const AdministrationPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="hub-projects"
                title="CMS Hub - Managing your Organisation"
                video={<HelpVideo link="https://www.youtube.com/embed/b3qeIUL-dfo" title="CMS Hub - Managing Organisation" />}
            >
                
            </SliderWithVideo>
        </div>
    );
};

export default AdministrationPage;
