import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Users
import UsersStepper from './Users/UsersStepper';
import UsersScrollList from './Users/UsersScrollList';
import UsersContent from './Users/UsersContent';

const UsersPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="manage-users"
                title="Managing Users"
                video={<HelpVideo link="https://www.youtube.com/embed/3PfNQ4FjmU4" title="OSS - Daily Reports Tutorial" />}
                stepper={<UsersStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<UsersScrollList />} 
                >
                    <InstructionScrollField 
                        title="Managing Users"
                    >
                        <UsersContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default UsersPage;
