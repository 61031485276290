import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../styles/help-page/SystemHelp.module.css';
import HubMenuTab from './HubMenuTab';
import { BookmarkCheck, BookmarkCheckFill, Gear } from 'react-bootstrap-icons';

const HubMenuTabs = () => {
    return(
        <div className={styles.helpMenuBox}>
            <div className={styles.helpMenuTabList}>
                <Link to="/hub/approve/sm"><HubMenuTab tabText="Approval as SM" tabIcon={<BookmarkCheck/>} /></Link>
                <Link to="/hub/approve/at"><HubMenuTab tabText="Approval as AT" tabIcon={<BookmarkCheckFill/>} /></Link>
                <Link to="/hub/admin"><HubMenuTab tabText="Administration" tabIcon={<Gear/>} /></Link>
            </div>
        </div>
    );
};

export default HubMenuTabs;