import React from 'react';
import styles from "../../../../styles/help-page/SystemHelp.module.css";

// Import of images
import ravQAImg1 from "../../../../media/help-section/rav/help-rav-qa-1.png";
import ravQAImg2 from "../../../../media/help-section/rav/help-rav-qa-2.png";
import ravQAImg3 from "../../../../media/help-section/rav/help-rav-qa-3.png";
import ravQAImg4 from "../../../../media/help-section/rav/help-rav-qa-4.png";
import ravQAImg5 from "../../../../media/help-section/rav/help-rav-qa-5.png";
import ravQAImg6 from "../../../../media/help-section/rav/help-rav-qa-6.png";
import ravQAImg7 from "../../../../media/help-section/rav/help-rav-qa-7.png";
import ravQAImg8 from "../../../../media/help-section/rav/help-rav-qa-8.png";
import ravQAImg9 from "../../../../media/help-section/rav/help-rav-qa-9.png";
import ravQAImg10 from "../../../../media/help-section/rav/help-rav-qa-10.png";

const QAContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="qa-templates">Managing QA Templates</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Adding a new Template</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen1" src={ravQAImg1} className={styles.helpImgSm}></img>
                        <p>
                            In order to access the functionalities of Quality Assessment management, please find the grey "Manage Quality Assessments" button and press on it. Below, you will find icons linking to individual options available in the module.
                        </p>
                        <p>
                            To add a new Template, press the "Add a Template" button, as shown on the screenshot above. You should see a form displayed below.
                        </p>
                        <img alt="screen2" src={ravQAImg2}></img>
                        <p>
                            The first field is a texbox where a <b>Category (Template) Name</b> should be entered. To load some of the <b>verification questions</b> from an existing category, please choose one of the categories from a drop-down list. The question will be loaded into the form automatically.
                        </p>
                        <p>
                            To add the <b>verification questions manually</b>, fill in the textboxes placed in the second part of the form. To <b>add</b> more questions, press the <b>green plus button</b> displayed at the bottom.
                        </p>
                        <p>
                            To mark the question as <b>crucial</b>, tick the checkbox corresponding to the given question. <b>Crucial Questions</b> are required and, at later stages, the QA can not be submitted if all the crucial questions are not marked as confirmed.
                        </p>
                        <p>
                            To add the template to the system, press the <b>Submit</b> button at the bottom and wait for the confirmation prompt message.
                        </p>
                    </div>
                </div>

                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Managing Templates</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen3" src={ravQAImg3} className={styles.helpImgSm}></img>
                        <p>
                            To access the functionalities of Quality Assessment Templates management, please press on the icon displayed on the screenshot above. You will get redirected to the page where all the available categories are visible in the table. You can use the search box on the top to look for a given category.<br></br><br></br>To preview any of the templates, press the <b>View</b> button. To edit a category, choose the <b>Edit</b> button.
                        </p>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Editing Templates</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen4" src={ravQAImg4}></img>
                        <p>
                            After you press the "Edit" button in the table, you will see a form containing all the current questions and selected options of the given category. From there, you can edit any of the questions, alter the name of the template, as well as add or delete questions. To confirm, press the <b>Submit</b> button at the bottom of the form.
                        </p>
                    </div>
                </div>

                <div className="instructionSection">
                    <div className={styles.instructionSectionTitle} id="qa-adding">Adding new Quality Assessments</div>
                    <div className={styles.instructionSectionTxtArea}>
                        <h3>
                            &#8226; <b>Adding Quality Assessment from the menu</b>
                        </h3>
                        <img alt="screen5" src={ravQAImg5} className={styles.helpImgSm}></img><br></br><br></br>
                        <p>
                            To start adding a new Quality Assessment from the menu, please select the icon displayed on the screenshot below. You will then be redirected to the following page:
                        </p>
                        <img alt="screen6" src={ravQAImg6}></img><br></br><br></br>
                        <p>
                            If you wish to match your Quality Assessment to one of the Price Works, select, in a following order:
                        </p>
                        <ul>
                            <li>Subcontractor</li>
                            <li>Price Work</li>
                            <li>QA Category</li>
                        </ul>
                        <p>
                            Alternatively, if you want to add a Quality Assessment <b>without a Price Work</b>, switch the "Price Work" button placed at the top, and select:
                        </p>
                        <ul>
                            <li>Subcontractor</li>
                            <li>QA Category</li>
                        </ul>
                        <p>
                            After you choose all the options, a following form will apear on the webpage:
                        </p>
                        <img alt="screen7" src={ravQAImg7} className={styles.helpImgMd}></img>
                    </div>
                    <div className={styles.instructionSectionTxtArea}>
                        <h3>
                            &#8226; <b>Category, Block, Level and Plot</b>
                        </h3>
                        <p>
                            Firstly, pleae choose your Project. Then, fill in the Block, Level and Plot values.<br></br>
                            For a given category, aforementioned values must be unique. In order for the uniqueness to be checked, please press the "Verify" button. If the uniqueness is not confirmed first, it will not be possible to submit the Quality Assessment. Everytime any changes in the Project, Block, Level and Plot fields are introduced, the QA must be verified again.
                        </p>
                    </div>
                    <div className={styles.instructionSectionTxtArea}>
                        <h3>
                            &#8226; <b>Verification Questions</b>
                        </h3>
                        <p>
                            In the "Verification Questions" field you will find all the questions that have to be checked while filling in the QA form. The required questions are marked with the "tick" icon in the "Crucial" column. <b>If any of the "Crucial" questions is not confirmed, it will not be possible to submit the QA.</b>
                        </p>
                    </div>
                    <div className={styles.instructionSectionTxtArea}>
                        <h3>
                            &#8226; <b>QA Files</b>
                        </h3>
                        <ul>
                            <li><b>On-Site Confirmation Page</b> - It is a required field. The photo has to be taken on the site and uploaded.</li>
                            <li><b>Construction Drawing</b> - Required file, an image/PDF document of the construction drawing.</li>
                            <li><b>Additional Files</b> - Additional files that you may attach to the Quality Assessment.</li>
                        </ul>
                        <p>
                            Files can be submitted in a JPG/JPEG/PNG or PDF format.
                        </p>
                    </div>
                    <div className={styles.instructionSectionTxtArea}>
                        <h3>
                            &#8226; <b>Submission</b>
                        </h3>
                        <p>
                            To upload your QA to the system, please press the <b>Submit</b> button. Once you do it, a confirmation modal should appear on the screen. Pressing the button placed on the modal will redirect you to the "QAs Management" page. In the meantime, a PDF document of your Quality Assessment will be automatically generated by the system.
                        </p>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="qa-managing">Managing Quality Assessments</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Manager Side - Your QAs</b>
                    </h3>
                    <p>
                        To access all your previously added Quality Assessments, please access the <b>Your QAs</b> page. From there, you can preview and filter all QAs which had been added from your account. QAs can be filtered by
                    </p>
                    <ul>
                        <li>Order (New to old / Old to new)</li>
                        <li>Date</li>
                        <li>Date Range</li>
                    </ul>
                    <p>
                        In the table, you can see details regarding each QA. If you wish to preview more details, press the <b>"View"</b> button in the corresponding row. <br /><br />
                        If the QA had not been attached to a voucher yet, you can <b>Edit</b> it. In order to do that, please press the <b>"Edit" button</b> in a corresponding table row. You can edit the following fields:
                    </p>
                    <ul>
                        <li>Block, Level, Plot;</li>
                        <li>Set questions as confirmed/not confirmed;</li>
                        <li>Delete and update any files;</li>
                        <li>Works Done Field.</li>
                    </ul>
                    <p>
                        To download the PDF Document of your QA, press the PDF icon. The download process will start promptly.
                    </p>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Administrator Side - All QAs</b>
                    </h3>
                    <p>
                        To access the funcionality of previewing all the QAs which had been added to the system, please choose the <b>"View all QAs"</b> option under the "Quality Assessments" tab. The following page will be displayed:
                    </p>
                    <img alt="screen8" src={ravQAImg8}></img>
                    <p>
                        In order to display QAs on the page, firstly choose your <b>searching options</b>, as displayed on the screenshot above. If you do not want to filter by any of the options, please leave the field empty. To get the result, press the <b>"Find"</b> button.
                    </p>
                    <img alt="screen9" src={ravQAImg9}></img>
                    <p>
                        After the results are retrieved, you will be able to see details regarding each QAs in the table columns, as well as perform a set of actions for each Quality Assessment. Available actions are:
                    </p>
                    <ul>
                        <li><b>View</b> - after you press the "View" button, you will get redirected to the "Preview" page, where you will be able to see all the details regarding the QA, as well as download the files.</li>
                        <li><b>Warning</b> - if you want to send a warning regarding a chosen QA, please press the yellow "Warning button". A pop-up window displayed on the screenshot below will appear promptly: <br></br><br></br>
                        <img alt="screen10" src={ravQAImg10} className={styles.helpImgMd}></img><br></br><br></br>
                        In the textarea, you can enter any comments you would like to forward to the manager who had added the given QA. An automatic e-mail with your comment will be sent to the manager. They will also be able to access the comment from their account in the RAV System.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="qa-testing">Pressure Tests</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Add and Manage Pressure Tests</b>
                    </h3>
                    <p>
                        In order to issue a Pressure Test, firstly go to the "Your QAs" section. Find the QA to which you would like to attach the Test, and press the <b>"Issue"</b> button. You will then get redirected to the page where you will be able to fill in details about the test and <b>Submit</b> it. <br></br><br></br>
                        To <b>manage</b> your Pressure Tests, press on the <b>Pressure Tests</b> icon under the Quality Assessment section. You will see a table with all your previous pressure tests displayed. From there, you can edit or delete your test, <b>if the attached QA had not been added to the Voucher before.</b> You can also <b>download</b> the PDF file of your test by pressing the corresponding PDF icon.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default QAContent;