import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  
  export default function SimpleList() {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItemLink button href="#vouchers-adding">
            <ListItemIcon>
              <span className="material-icons">control_point</span>
            </ListItemIcon>
            <ListItemText primary="Adding vouchers" />
          </ListItemLink>
          <ListItemLink button href="#vouchers-office">
            <ListItemIcon>
              <span className="material-icons">
              list
              </span>
            </ListItemIcon>
            <ListItemText primary="Office Side" />
          </ListItemLink>
          <ListItemLink button href="#vouchers-manager">
            <ListItemIcon>
              <span className="material-icons">
              assignment_ind
              </span>
            </ListItemIcon>
            <ListItemText primary="Manager Side" />
          </ListItemLink>
          <ListItemLink button href="#vouchers-admin">
            <ListItemIcon>
              <span className="material-icons">
              admin_panel_settings
              </span>
            </ListItemIcon>
            <ListItemText primary="Admin Side" />
          </ListItemLink>
        </List>
      </div>
    );
  }
  