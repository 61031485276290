import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "../../../../styles/help-page/SystemHelp.module.css";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
    },
    button: {
      marginTop: "2rem",
      marginRight: theme.spacing(1),
    },
    instructions: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      margin: '0 auto',
      textAlign: 'justify',
    },
  }));
  
  function getSteps() {
    return ['Add documents', 'Manage documents', 'Speedfit', 'Sage Spreadsheets', 'Run Comparisons'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'In the "Add a Document" section, you need to fill in the following fields: Document Number, Date, Type (Statement/Non-Statement/Materials), for whom the document is, Project, Tax Code, Details, as well as the Net, VAT and Nominal values. Optionally, you can input PO Number and any additional comments. By pressing the checkboxes, you can also tick if the document is a credit note, if it contains a discount from Speedfit, if it had been paid already, as well as if it is in Sage already. Finally, you need to choose a Document file in order to upload it. To finish, please press the "Submit" button.';
      case 1:
        return 'In order to Manage previously added documents, you need to access the Manage Invoices or Manage Materials pages. From there, you can preview the document, filter them by, among many, sage status, date, type. Moreover, you can mark the document as "Paid" by pressing the corresponding checkbox (or the "All" button above the table, if you wish to tick all the documents) and then choosing the "Mark Paid" button or, alternatively, by pressing the green "Paid" button for the given document. In order to add the Sage Number, you need to press the "Add" button in the corresponding row. Furthermore, there are options to edit the document, preview its file, or all its details.';
      case 2:
        return 'In order to manage Speedfit, please go to the "Manage Speedfit" section. Firstly, choose the date range and then press the "Download" button. The report in form of an Excel Spreadsheet will be generated automatically and the download process should start promptly. In the table, you will be able to see separate documents. You can download the file of each document by pressing the "PDF" icon in the corresponding row.';
      case 3:
        return 'The RAV System allows you to generate spreadhsheets which can then be imported into Sage. You can also import a spreadsheet into the system. To do that, please access the "Export/Import Sage Spreadsheet" page. If you wish to export the raport, firstly choose the date range, and then press the "Export" button. Alternatively, you can import a spreadsheet by choosing the relevant file in XLS/XLSX format and pressing the "Import" button.';
      case 4: 
      return 'If you use our EMS System, you can compare the Sage Excel Statement with the EMS Excel Statement by simply uploading the XLS/XLSX/CSV files. If you wish to enable comparing by prices, enable the "Switch" button placed below the file input fields. Finally, press the "Compare" button to retrieve the results.';
      default:
        return 'Unknown step';
    }
  }

const InvoicesManagementStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
  
    const isStepOptional = (step) => {
      return step === 10;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <div className={classes.root}>
        <Stepper className={styles.stepperScroll} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                Congratulations! You have learnt how to manage documents with RAV.
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
  
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default InvoicesManagementStepper;