import React from 'react';

import styles from '../../styles/help-page/HelpMain.module.css';

import HelpPageMainTop from './HelpPageMainTop';
import HelpMain from './HelpMain';

const HelpPage = () => {   
    return(
        <div className={styles.helpSysMain}>
            <HelpPageMainTop />
            <HelpMain />
        </div>
    );
};

export default HelpPage;