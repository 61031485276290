import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ossImg9 from "../../../../media/help-section/oss/ossscreen9.png"
import ossImg10 from "../../../../media/help-section/oss/ossscreen10.png"
import ossImg11 from "../../../../media/help-section/oss/ossscreen11.png"
import ossImg12 from "../../../../media/help-section/oss/ossscreen12.png"

const DailyReportContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="report-weekly">Daily Report</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Reviewing Daily Reports</b>
                        </h3>

                            In order to access the <strong>daily report</strong> section, please navigate to the "Daily report" tab in the main menu. Once you get redirected to the appropriate page, you will see the list of users who clocked-in/out at the <strong>current day</strong>, as depicted on the screenshot below:
                            <br></br><br></br>
                            <img alt="screen1" src={ossImg9}></img>
                            <br></br><br></br>
                            In the report, you can preview each user's:
                            <ul>
                                <li>Name and Surname;</li>
                                <li>Clock-in and Clock-out times; </li>
                                <li>Location at which they clocked-in/out;</li>
                                <li>Their clock-in/out selfie.</li>
                            </ul>
                            In order to preview the user's location in form of an embedded map, press on the corresponding map icon.<br></br>
                            In order to preview employee's selfie, press on the corresponding small employee icon. Subsequently, a pop-up window with the selfie should be displayed, such as on the screenshot below: 
                            <br></br><br></br>
                            <img alt="screen2" src={ossImg10}></img>
                            <br></br><br></br>
                            To <strong>navigate to other day</strong>, you can use the buttons depicted below:
                            <br></br><br></br>
                            <img alt="screen3" src={ossImg11} className={styles.helpImgMd}></img>
                            <br></br><br></br>
                            Alternatively, you can <strong>use a calendar to choose the date</strong>. To access the calendar, press on the date displayed under the "Daily Summary" header. A calendar should be displayed, as depicted on the screenshot below. Choose any date and the daily report from that day will be rendered on the page.
                            <br></br><br></br>
                            <img alt="screen4" src={ossImg12} className={styles.helpImgMd}></img>
                            <br></br><br></br>
                        </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default DailyReportContent;