import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Documents
import DocsStepper from './Docs/DocsStepper';
import DocsScrollList from './Docs/DocsScrollList';
import DocsContent from './Docs/DocsContent';

const DocumentsPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-documents"
                title="Managing Documents"
                video={<HelpVideo title="RAV - Documents Tutorial" link="https://www.youtube.com/embed/axrEXpx9p3U" />}
                stepper={<DocsStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<DocsScrollList />} 
                >
                    <InstructionScrollField 
                        title="Instructions for Documents"
                    >
                        <DocsContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default DocumentsPage;
