import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ossImg7 from "../../../../media/help-section/oss/ossscreen7.png"
import ossImg8 from "../../../../media/help-section/oss/ossscreen8.png"

const WeeklyReportContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="report-weekly">Weekly Report</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Reviewing Weekly Reports</b>
                        </h3>

                            In order to access the <strong>weekly report</strong> section, navigate to the "Weekly report" tab in the main menu. Once you get redirected to the appropriate page, choose the following options from the two drop-down lists:
                            <ul>
                                <li><strong>Week</strong> from which you want to review the entries;</li>
                                <li><strong>Project</strong> from which you want to review the entries ("all projects" option available).</li>
                            </ul>
                            After you select the above option, a table containing all relevant users' entries will be displayed. In the table, the following information is contained:
                            <ul>
                                <li>Employee's name and surname;</li>
                                <li>Worked hours in each day of the week;</li>
                                <li>Weekly total;</li>
                                <li>Grand total.</li>
                            </ul>
                            If you wish to preview the details of any user whose name is displayed in the table, press on their name.
                            <br></br><br></br>
                            <img alt="screen2" src={ossImg7}></img>
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="csv-report">CSV Spreadsheet of Weekly Report</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Generating a Spreadsheet</b>
                        </h3>

                            <br></br><br></br>
                            <img alt="screen2" src={ossImg8}></img>
                            <br></br><br></br>

                            The system can automatically generate a CSV spreadhseet of the chosen weekly report. In order to do that, press the "Download" button depcited on the screenshot above. The download process will start automatically, and you will be given an option to save the spreadsheet in your machine.
                        </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default WeeklyReportContent;