import React from 'react';
import styles from '../../../styles/help-page/SystemHelp.module.css';

const RavMenuTab = props => {
    return(
        <div className={styles.helpMenuTab}>
            <span className={styles.helpMenuTabIcon}>{props.tabIcon}</span>
            <span>{props.tabText}</span>
        </div>
    );
};

export default RavMenuTab;