import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "../../../../styles/help-page/SystemHelp.module.css";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
    },
    button: {
      marginTop: "2rem",
      marginRight: theme.spacing(1),
    },
    instructions: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      margin: '0 auto',
      textAlign: 'justify',
    },
  }));
  
  function getSteps() {
    return ['Add Project', 'Projects List', 'Update Project'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'In order to add a new project to the system, firstly navigate to the "Projects" tab. Subsequently, press the "Add Project" button. You will then be redirected to a new page, where you will be able to enter name, location, description, as well as default working hours of a given project. ';
      case 1:
        return 'To browse through the previously added projects, navigate to the "Projects" tab. From there, you can preview the projects labels, as well as their locations on the map. In order to view or update further details, please press on the label of the chosen entry.';
      case 2:
        return 'After you choose the project you wish to update from the Projects List, you will be shown a form with the details of the entry: its label, location, description, as well as the hours of operations. Moreover, you can delete the given project, or mark it as unactive. To finish, press the "Update project" button placed at the bottom of the page.';
      default:
        return 'Unknown step';
    }
  }

const ProjectsStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
  
    const isStepOptional = (step) => {
      return step === 10;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <div className={classes.root}>
        <Stepper className={styles.stepperScroll} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed.
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
  
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default ProjectsStepper;