import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Price Works
import PWStepper from './PW/PWStepper';
import PWScrollList from './PW/PWScrollList';
import PWContent from './PW/PWContent';

const PriceWorksPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-price-works"
                title="Managing Price Works"
                video={<HelpVideo link="https://www.youtube.com/embed/oosWL-UVMlY" title="RAV - Price Works Tutorial" />}
                stepper={<PWStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<PWScrollList />} 
                >
                    <InstructionScrollField 
                        title="Instructions for Price Works"
                    >
                        <PWContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default PriceWorksPage;
