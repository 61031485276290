import React from 'react';
import FAQCards from '../FAQCards';
import FAQCard from '../FAQCard';

const OssFAQ = () => {
    return (
        <div className="">
            <FAQCards>
                <FAQCard question="What is On-site Selfie?" answer="On-site Selfie is an online system where your employees, operatives and subcontractors clock in and out simply by uploading selfies." hint="Press the arrow below for the answer" />
                <FAQCard question="Who is On-site Selfie for?" answer="On-site Selfie can be used by any company who wishes to significantly enhance their ways of timesheet and payroll management. " hint="Press the arrow below for the answer" />
                <FAQCard question="What are the core features of On-site Selfie?" answer="The core features of the On-site Selfie system are: clocking-in and -out from work simply by taking a selfie, automatic location detection, management of your users and projects, weekly reports with calculated working hours of your employees, daily reports, timesheet builder, allowing you to preview logs, selfies and location details of individual users." hint="Press the arrow below for the answer" />
            </FAQCards>
            <FAQCards>
                <FAQCard question="How many times can my employees log in and out to work on a given day?" answer="Currently, employees can clock in and out only one time per day. Once the user logs in and takes a selfie to indicate that they had started their shift, they will only be able to logout during that day. When the user finishes their shift and takes the “clock-out selfie”, they will need to wait until the next day to clock-in again." hint="Press the arrow below for the answer" />
                <FAQCard question="How does the lunchtime deduction work?" answer="If you wish to deduct the break time from the calculated billable hours of your employees, you can set a default lunchtime break for each of your projects in Update Projects. Please provide a value in minutes. If you would prefer not to deduct any hours, leave the input field as 0." hint="Press the arrow below for the answer" />
                <FAQCard question="At what time does the new working day start?" answer="The system is automatically restarted every night after the midnight, and the users can clock-in into work in order to indicate the start of the shift again." hint="Press the arrow below for the answer" />
            </FAQCards>
            <FAQCards>
                <FAQCard question="On what day does the new week start in the system?" answer="Each company has an option to choose the day which will be the beginning of a new working week. This could be either a Monday, or e.g. a Sunday or Saturday." hint="Press the arrow below for the answer" />
                <FAQCard question="What should I do if for some users the location is not displayed in the logs?" answer="If the location (the map icon beside each log) of some users doesn't show up, please ensure that the location sharing is enabled both in the browser and in the device used to clock in or out from work." hint="Press the arrow below for the answer" />
                <FAQCard question="What should a user do if they encounter an unexpected error while clocking in?" answer="An unexpected error may occur when the internet connection is unstable or there is a problem with the GPS location. In such a case, users can submit a note to the company’s administrator to inform them of the issue and confirm that they are on site. The administrator will then be able to adjust their working hours in the timesheet builder." hint="Press the arrow below for the answer" />
            </FAQCards>
            <FAQCards>
                <FAQCard question="How does the monthly subscription work?" answer="We offer a monthly subscription of the On-site Selfie system which is paid per each active user in a given month. A user is billed for when their account was active for at least one day in that month. The rate per user may vary depending on the features including with the subscription." hint="Press the arrow below for the answer" />
                <FAQCard question="What happens if a user forgets their password?" answer="If the user forgets their password, it needs to be changed and provided to the employee by the company’s administrator. If you need to update the administrator’s password, please contact our IT Support." hint="Press the arrow below for the answer" />
                <FAQCard question="How can I contact you for further information?" answer="For any further information regarding the On-site Selfie system, please contact our IT Support under the following e-mail address: it@companiesms.co.uk" hint="Press the arrow below for the answer" />
            </FAQCards>
        </div>
    );

};

export default OssFAQ;