import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "../../../../styles/help-page/SystemHelp.module.css";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
    },
    button: {
      marginTop: "2rem",
      marginRight: theme.spacing(1),
    },
    instructions: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      margin: '0 auto',
      textAlign: 'justify',
    },
  }));
  
  function getSteps() {
    return ['Price Units', 'Templates', 'Adding price works', 'Managing price works'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'In order to create your own price work templates which you can then reuse to schedule work for your subcontractors, you first need to add payment units. Payment units are individual jobs that you will pay your employees for, such as Casing or Dry Riser. You need to specify the unit (e.g. 1 linear meter), the fixed price for the unit (e.g. 10 GBP per 1 linear meter) and deduction rate (e.g. for testing) which could be anywhere between 0 and 100 procent.';
      case 1:
        return 'Price work templates are reusable templates of sets of tasks that have to be conducted by subcontractors for the specific job to be accomplished. They are composed of previously added payment units. An example of a price work template could be Bracketry with two units: Uninstrut and Basket, both worth 3.50 GBP per unit. To add your own template, you need to give it a name and select payment units from the list. You can add as many as you need. If you know that for this specific task subcontractors always install 10 linear meters of the unit, you can increase the quantity of it to 10 or you can make the template more versatile and leave quantities one.';
      case 2:
        return 'To add a price work, you have to select the subcontractor who is going to conduct the works and assign the work to the specific project you are managing. Then, you can choose a price work template created earlier. All the smaller tasks within that price work template ("payment units") will appear. You can adjust their quantities and price per unit - the total amount of money to be paid for this price work as well as total deductions will be calculated automatically. If you want to schedule the same works with the same amount payable for the same subcontractor (e.g. for 3 consecutive days), you can change the quantity of price works to more than 1 to add more of them at once.';
      case 3:
        return 'In the Manage Price Works tab, you can view all your currently active price works which need a quality assessment to be issued. You can edit some price work details such as the description or the date by pressing Edit. If you made a mistake or the price work is redundant, you can simply delete it. To issue a quality assessment for the specific price work (after it was conducted by the subcontractor), you can press the green tick in the QA column.';
      default:
        return 'Unknown step';
    }
  }

const PWStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
  
    const isStepOptional = (step) => {
      return step === 10;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <div className={classes.root}>
        <Stepper className={styles.stepperScroll} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                Congratulation! You have learnt how to manage price works.
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
  
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default PWStepper;