import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink button href="#docs-adding">
          <ListItemIcon>
            <span className="material-icons">control_point</span>
          </ListItemIcon>
          <ListItemText primary="Add documents" />
        </ListItemLink>
        <ListItemLink button href="#docs-managing">
          <ListItemIcon>
            <span className="material-icons">
              list
          </span>
          </ListItemIcon>
          <ListItemText primary="Manage documents" />
        </ListItemLink>
        <ListItemLink button href="#docs-editing">
          <ListItemIcon>
            <span className="material-icons">
              build
          </span>
          </ListItemIcon>
          <ListItemText primary="Edit documents" />
        </ListItemLink>
        <ListItemLink button href="#docs-previewing">
          <ListItemIcon>
            <span className="material-icons">
              wallpaper
          </span>
          </ListItemIcon>
          <ListItemText primary="Preview" />
        </ListItemLink>
        <ListItemLink button href="#docs-materials">
          <ListItemIcon>
            <span className="material-icons">
              assignment
          </span>
          </ListItemIcon>
          <ListItemText primary="Materials" />
        </ListItemLink>
        <ListItemLink button href="#docs-speedfit">
          <ListItemIcon>
            <span className="material-icons">
              content_cut
          </span>
          </ListItemIcon>
          <ListItemText primary="Speedfit" />
        </ListItemLink>
        <ListItemLink button href="#docs-sage">
          <ListItemIcon>
            <span className="material-icons">
              library_books
          </span>
          </ListItemIcon>
          <ListItemText primary="Sage Spreadsheets" />
        </ListItemLink>
      </List>
      <ListItemLink button href="#docs-comparing">
        <ListItemIcon>
          <span className="material-icons">
            pie_chart
          </span>
        </ListItemIcon>
        <ListItemText primary="Run Comparisons" />
      </ListItemLink>
      <Divider />
    </div>
  );
}
