import React from 'react';

import styles from "../../styles/help-page/SystemHelp.module.css";

const InstructionScrollField = props => {
    return (
        <div className={styles.instructionWithTitle}>
            <h2>{props.title}</h2>
            <div className={styles.instructionScrollBox}>
                {props.children}
            </div>
        </div>
    );
}

export default InstructionScrollField;