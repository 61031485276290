import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ossImg4 from "../../../../media/help-section/oss/ossscreen4.png"
import ossImg5 from "../../../../media/help-section/oss/ossscreen5.png"
import ossImg6 from "../../../../media/help-section/oss/ossscreen6.png"

const UsersContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="projects-adding">Adding Users</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Adding a new User</b>
                        </h3>

                            In order to <b>add</b> a new user, please proceed to the "Users" tab, accessible from the main navigation bar. From there, choose the "Add User" option. The following form will then be displayed:
                            <br></br><br></br>
                            <img alt="screen1" src={ossImg5}></img>
                            <br></br><br></br>
                            Please fill in all the input fields with the details regarding the new User:
                            <ul>
                            <li>User role - check whether the user is an employee, supervisor or administrator of the system;</li>
                            <li>First Name;</li>
                            <li>Surname;</li>
                            <li>E-Mail;</li>
                            <li>Password.</li>
                        </ul>
                        <br></br><br></br>
                            Users with assigned <strong>"Administrator"</strong> role are granted access to all the functionalities of the system, such as adding users, projects, adjusting timesheets.
                            <br></br>
                            After you enter all the required details, press the red "Submit" button displayed at the end of the form. Once the user is added, you will be redirected to the Users List, and a relevant notification informing about a successfull operation will be displayed.
                            <br></br><br></br>
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="users-list">Users List</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>List of users</b>
                        </h3>

                            You can browse the list of previously added users by navigating to the "Users" tab in the main menu. In the list, you can preview:
                            <ul>
                                <li>User's name and surname;</li>
                                <li>User's email address;</li>
                                <li>Active from and Active to dates (if applicable); *</li>
                                <li>User's role; **</li>
                                <li>If the user is new. ***</li>
                            </ul>
                            * - If the user is marked as unactive in the system, the "Date to" value will be presented. Inactive users are displayed at the bottom of the whole list.<br></br>
                            ** - If the user is assigned the role of supervisor or administrator, relevant information will be displayed in the list (as seen on the screenshot below, where user Karen Smith is assigned the role of supervisor).<br></br>
                            *** - If the user had been added to the system recently, a "new" icon will be displayed next to his name.
                            <br></br><br></br>
                            <img alt="screen2" src={ossImg4}></img>
                            <br></br><br></br>
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="updating-users">Updating users</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Updating the details of the chosen user</b>
                        </h3>

                            In order to access the details of the given user, first choose them from the Users List. The form with the relevant input fields will then be displayed, as depicted on the screenshot below:
                            <br></br><br></br>
                            <img alt="screen3" src={ossImg6}></img>
                            <br></br><br></br>
                            After you adjust chosen details of the user, press the red "Update user" button placed at the end of the form.
                        </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default UsersContent;