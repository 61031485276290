import React from 'react';
import OssMenuTabs from './OssMenuTabs';

const OssHelp = () => {
    return (
        <div className="">
            <OssMenuTabs />
        </div>
    );

};

export default OssHelp;
