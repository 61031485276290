import React from 'react';
import HubMenuTabs from './HubMenuTabs';

const HubHelp = () => {
    return (
        <div className="">
            <HubMenuTabs />
        </div>
    );

};

export default HubHelp;
