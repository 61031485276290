import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "../../../../styles/help-page/SystemHelp.module.css";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
    },
    button: {
      marginTop: "2rem",
      marginRight: theme.spacing(1),
    },
    instructions: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      margin: '0 auto',
      textAlign: 'justify',
    },
  }));
  
  function getSteps() {
    return ['Issue', 'View', 'Edit', 'Test', 'More Privileges'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'You can issue a quality assessment in two ways: either by going directly to Add a New QA in Manage Quality Assessments or by going to Manage Price Works and pressing the green tick in the row of the price work for which you are issuing the QA. If you want to issue a QA not associated with any price work, on the Add a New QA page press the switch button Disable Price Works. To proceed to QA questions, you need to select the subcontractor, then select the price work (if enabled) and finally the QA template. When the form appears, fill in all the necessary details. Press "Verify" by the Block/Level/Plot to make sure that this QA is unique. If the question is crucial, you must check it, otherwise you will not be able to issue the QA. You are obliged to attach an on-site image and the respective drawing. Optionally, you can include up to 5 extra photos. To issue the QA, press Submit.';
      case 1:
        return `In order to view your quality assessments, navigate to Your QAs in the Manage Quality Assessments section. By default, you'll be able to view the QAs that you issued over the last two weeks. You can filter the QAs to search by date or within a range of dates (by doing that, you'll be able to preview any QAs that you issued in the past). To view the details, press the View button in the respective row, to edit a QA, press Edit. To download a PDF, press the PDF icon in the last column of the table.`;
      case 2:
        return `You can edit a QA by pressing the Edit button in the respective row in Manage Quality Assessments (Your QAs). You'll be able to modify every aspect of the QA, such as adding or deleting images, changing the answer to questions or adjusting the block, plot or level. If by mistake, you've selected the wrong subcontractor, wrong project or wrong QA template, you'll need to contact the Office/Administrator to inform them of the error. The Office will then be able to delete the QA so that you can issue it again.`;
      case 3:
        return 'Some price works, matched with QAs, include money deductions for testing. RAV allows to issue such tests and later return the deducted value to the subcontractor. To issue a pressure test, you need to navigate to Your QAs and press the Testing button in the respective table row. You will be redirected to the simple form which you need to fill in. After that, a pressure test QA will be generated (including the PDF) and will be accessible from Pressure Tests in Manage Quality Assessments section of RAV. You will then be able to add such pressure test QAs to your vouchers to return deductions to your subcontractors.';
      case 4: 
      return 'Site Managers and System Administrators have access to more features in the QA section, most notably View All QAs. They are able to view quality assessments added at any time, by anyone for any project. They can set all the filters, including the date range, and display the QAs. Their prerogatives also involve deleting a QA permanently from the system or flagging it to the issuer with a custom comment. They can also edit any QA added to the system.';
      default:
        return 'Unknown step';
    }
  }

const QAStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
  
    const isStepOptional = (step) => {
      return step === 10;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <div className={classes.root}>
        <Stepper className={styles.stepperScroll} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                Congratulations! You have learnt how to issue and manage quality assessments in RAV.
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
  
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default QAStepper;