import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ravImg1 from "../../../../media/help-section/rav/help-rav-inv-1.png";
import ravImg2 from "../../../../media/help-section/rav/help-rav-inv-2.png";
import ravImg3 from "../../../../media/help-section/rav/help-rav-inv-3.png";
import ravImg4 from "../../../../media/help-section/rav/help-rav-inv-4.png";
import ravImg5 from "../../../../media/help-section/rav/help-rav-inv-5.png";
import ravImg6 from "../../../../media/help-section/rav/help-rav-inv-6.png";
import ravImg7 from "../../../../media/help-section/rav/help-rav-inv-7.png";
import ravImg8 from "../../../../media/help-section/rav/help-rav-inv-8.png";
import ravImg9 from "../../../../media/help-section/rav/help-rav-inv-9.png";
import ravImg10 from "../../../../media/help-section/rav/help-rav-inv-10.png";
import ravImg11 from "../../../../media/help-section/rav/help-rav-inv-11.png";
import ravImg12 from "../../../../media/help-section/rav/help-rav-inv-12.png";
import ravImg13 from "../../../../media/help-section/rav/help-rav-inv-13.png";
import ravImg14 from "../../../../media/help-section/rav/help-rav-inv-14.png";
import ravImg15 from "../../../../media/help-section/rav/help-rav-inv-15.png";
import ravImg16 from "../../../../media/help-section/rav/help-rav-inv-16.png";
import ravImg17 from "../../../../media/help-section/rav/help-rav-inv-17.png";
import ravImg18 from "../../../../media/help-section/rav/help-rav-inv-18.png";
import ravImg19 from "../../../../media/help-section/rav/help-rav-inv-19.png";
import ravImg20 from "../../../../media/help-section/rav/help-rav-inv-20.png";
import ravImg21 from "../../../../media/help-section/rav/help-rav-inv-21.png";
import ravImg22 from "../../../../media/help-section/rav/help-rav-inv-22.png";
import ravImg23 from "../../../../media/help-section/rav/help-rav-inv-23.png";
import ravImg24 from "../../../../media/help-section/rav/help-rav-inv-24.png";

const DocsContent = () => {
    return(
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-adding">Adding Documents</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Accessing Invoices Section</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen1" src={ravImg1}></img>
                        <div>
                            In order to access the functionalities of invoices management, please find the "Manage Invoices" button and press on it. Below, you will find icons linking to individual options available in the module. To <b>add</b> a new document, press the first icon "Add a Document". 
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Filling in the details</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen2" src={ravImg2}></img>
                        <div>
                            In order to add a new document to the system, you need to fill in the details into the input fields displayed on the image above.
                            Moreover, by ticking the checkboxes, you can select if a given document is a credit note, if it contains a discount from Speedfit, if it had been paid already, or if it had been input into the Sage system.<br></br>
                            Fields which are required have a small red star (<span className={styles.starColour}>*</span>) displayed next to the their title.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Uploading the file</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen3" src={ravImg3} className={styles.helpImgMd}></img>
                        <div>
                            In order to be able to add the document to the system, you will need to upload the file of the document (in the PDF or JPG/JPEG/PNG format). To do that, please press on the file input field placed on the left side, as displayed on the screenshot above. Alternatively, you can drag the file into the field. Finally, if all the required fields are filled in, press the <b>"Submit" button</b>. Once the document gets successfully added to the system, a green tick icon appears on the screen.
                        </div>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-managing">Managing Invoices</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Accessing the module</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen4" src={ravImg4} className={styles.helpImgSm}></img>
                        <div>
                            In order to access the "Manage Invoice" module, please press the icon shown above, under the "Manage Invoices" section in the system's dashboard.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Management Table</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen5" src={ravImg5}></img>
                        <div>
                            In the "Manage Invoices" section, you have an access to all the previously added invoices. The table columns contain information about the given invoice's number, PO number, date, proejct, sage number, supplier, as well as whether the invoice had been paid. You can also perform actions such as marking an invoice as paid, or adding a Sage number. Those functionalities are further explained in this section.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Adding a Sage Number</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <div className={styles.alignHelpImage}>
                            <img alt="screen6" src={ravImg6} className={styles.helpImgSm}></img>
                            <img alt="screen7" src={ravImg7} className={styles.helpImgSm}></img>
                        </div>
                        <div>
                            In order to add a sage number from the "Manage Invoices" page, firstly press the "Add" button in the corresponding row. Once the input text field appears, as on the screenshot above, enter the number. To confirm, press the green tick button. The number will automatically appear in the table row.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Marking Invoice as Paid</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <div className={styles.alignHelpImage}>
                            <img alt="screen8" src={ravImg8} className={styles.helpImgSm}></img>
                            <img alt="screen9" src={ravImg9} className={styles.helpImgSm}></img>
                        </div>
                        <div>
                            There are two ways which enable to mark the invoice as paid from the table:
                            <ul>
                                <li>Tick the checkbox in the corresponding row. Afterwards, press the "Mark Paid" button placed above the table. This way, you can mark more than one document as paid simultaneously. <b>Pressing the All button</b> will tick the checkboxes of all the invoices displayed in the column.</li>
                                <li>Press the <b>Paid</b> button in the corresponding table row.</li>
                            </ul>
                            After an invoice is marked as paid, the <b>green Paid button</b> gets changed into a <b>Tickbox icon</b>.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Filtering the Table</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen10" src={ravImg10}></img>
                        <div>
                            In order to filter the Invoices Table, firstly set your searching options. You can filter the entries by:
                            <ul>
                                <li>Type (Statement/Non-Statement)</li>
                                <li>Supplier</li>
                                <li>Date Range</li>
                            </ul>
                            To retrieve entries, press the "Search" button.<br></br>
                            Alternatively, you can press the <b>Switch button</b> and change the searching options from <b>Basic</b> to <b>Advanced</b>:
                            <img alt="screen11" src={ravImg11} style={{marginTop: '2rem'}}></img>
                            <br></br>This will enable you to browse the invoices by:
                            <ul>
                                <li>Sage Status (Added/To Be Added)</li>
                                <li>Invoice Number</li>
                                <li>PO Number</li>
                            </ul>
                            The last button enables you to <b>Reset</b> your searching options.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Other Options</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen8" src={ravImg12} className={styles.helpImgSm}></img>
                        <div>
                            Other options accessible from the table are:
                            <ul>
                                <li>Updating the Invoice</li>
                                <li>Previewing the whole entry</li>
                                <li>Previewing the attached file</li>
                            </ul>
                            To read about the aforementioned options in detail, please refer to the sections below.
                        </div>
                    </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-editing">Editing Documents</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Access the functionality</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen13" src={ravImg13} className={styles.helpImgSm}></img>
                        <div>
                            In order to start editing a chosen invoice, please go to the <b>"Manage Invoices"</b> section. Afterwards, press the grey "Edit" button in the row corresponding to the chosen document.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Update the document</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen14" src={ravImg14}></img>
                        <div>
                            After you access the "Update Invoice" page, you can edit any previously entered values or change the document's file. <br></br>
                            To delete the entry, press the red "Delete" button on the left side. <br></br>
                            To save your changes, press the green "Submit" button on the right side.
                        </div>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-previewing">Previewing Documents</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Preview the whole document</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen15" src={ravImg15} className={styles.helpImgSm}></img>
                        <div>
                            In order to preview the whole invoice, please go to the <b>"Manage Invoices"</b> section. Afterwards, press the grey "»" button in the row corresponding to the chosen document. You will be redirected to the page containing the document's details, as well as the file placed in the frame.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Preview the document's file</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen16" src={ravImg16} className={styles.helpImgSm}></img>
                        <div>
                            In order to preview the whole invoice, please go to the <b>"Manage Invoices"</b> section. Afterwards, press the PDF icon in the row corresponding to the chosen document. A pop-up box containing the preview of the file attached to the invoice will appear on the screen.
                        </div>
                        <img alt="screen17" src={ravImg17}></img>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-materials">Materials</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Adding Materials</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <div>
                            To add a Material, please refer to the <b>"Add a Document"</b> section and choose <b>"Materials"</b> as the <b>"Document Type"</b>.
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Managing Materials</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen18" src={ravImg18} className={styles.helpImgSm}></img>
                        <div>
                            In order to go to the "Manage Materials" section, press the icon shown above. You will then be redirected to the page where you can access all the previously added Materials. <br></br><br></br>
                            <b>To read instructions regarding options such as edit, preview mark as paid, please refer to the relevant previous sections in the Documents module.</b>
                        </div>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Filtering Materials</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <div className={styles.alignHelpImage}>
                            <img alt="screen19" src={ravImg19}></img>
                            <img alt="screen20" src={ravImg20} className={styles.helpImgMd}></img>
                        </div>
                        <div>
                            To filter the Materials Table, firstly set your searching options. You can browse materials by:
                            <ul>
                                <li>Sage Status</li>
                                <li>Payment Date</li>
                                <li>Employee Name</li>
                                <li>Date Range</li>
                            </ul>
                            As shown on the second screenshot, you can press the switch button in order to toggle between Contract Employees and Subcontractors (for the drop-down list in the searching options).
                        </div>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-speedfit">Speedfit</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Managing Speedfit</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen21" src={ravImg21} className={styles.helpImgSm}></img>
                        <div>
                            In order to access the "Speedfit" functionality, please press the icon shown above under the "Manage Invoices" section. <br></br>
                            You will then get redirected to the "Manage Speedfit" section. Firstly, please choose the date range, as shown on the screenshot below. Then press the <b>"Download"</b> button. The spreadsheet will be generated automatically and the download process will start.
                        </div>
                        <img alt="screen22" src={ravImg22}></img>
                        <div>
                            The retrieved entries will appear in the table. The table columns contain information regarding the supplier, number and sage number. <br></br>
                            You can download individual files by pressing the "PDF" Icon.
                        </div>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-sage">Sage Spreadsheets</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Sage Spreadsheets Import and Export</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen20" src={ravImg23} className={styles.helpImgSm}></img>
                        <div>
                            In order to access the functionality, please press the icon shown above under the "Manage Invoices" section. <br></br>
                            You will then get redirected to the "Export/Import Sage Spreadsheet" section. Firstly, please choose the date range, as shown on the screenshot below. Then press the <b>"Download"</b> button. The spreadsheet will be generated automatically and the download process will start.
                        </div>
                        <img alt="screen22" src={ravImg24} className={styles.helpImgSm}></img>
                        <div>
                            The retrieved entries will appear in the table. The table columns contain information regarding the supplier, number and sage number. <br></br>
                            You can download individual files by pressing the "PDF" Icon.
                        </div>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="docs-comparing">Run Comparisons</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>
                        &#8226; <b>Accessing the functionality</b>
                    </h3>
                    <div className={styles.instructionSectionTxt}>
                        <img alt="screen24" src={ravImg24} className={styles.helpImgSm}></img>
                        <div>
                            In order to access the functionality, please press the icon shown above under the "Manage Invoices" section. <br></br>
                            You will then get redirected to the "Compare Invoices with Orders" section. Firstly, please choose the date range, as shown on the screenshot below. Then press the <b>"Download"</b> button. The spreadsheet will be generated automatically and the download process will start.
                        </div>
                        <img alt="screen22" src={ravImg22}></img>
                        <div>
                            The retrieved entries will appear in the table. The table columns contain information regarding the supplier, number and sage number. <br></br>
                            You can download individual files by pressing the "PDF" Icon.
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DocsContent;