import React from 'react';
import styles from '../../styles/help-page/HelpMain.module.css';

import helpImg from "../../media/icons/help.png";

const HelpPageMainTop = () => {

    return(
        <div className={styles.helpPageTop}>
            <div className={styles.helpPageTopTitle}>
                <p>CMS Help Hub</p>
                <img alt="help" src={helpImg} />
            </div>
            <div className={styles.dottedLine}></div>
        </div>
    );
};

export default HelpPageMainTop;