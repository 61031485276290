import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../styles/help-page/SystemHelp.module.css';
import OssMenuTab from './OssMenuTab';
import { QuestionCircle, Clock, PersonCircle, CardHeading, ListOl, CardChecklist, Table } from 'react-bootstrap-icons';

const RavMenuTabs = () => {
    return(
        <div className={styles.helpMenuBox}>
            <div className={styles.helpMenuTabList}>
                <Link to="/oss/faq"><OssMenuTab tabIcon={<QuestionCircle/>} tabText="FAQ" /></Link>
                <Link to="/oss/clocking"><OssMenuTab tabIcon={<Clock/>} tabText="Clocking In/Out" /></Link>
                <Link to="/oss/users"><OssMenuTab tabIcon={<PersonCircle/>} tabText="System Users" /></Link>
            </div>
            <div className={styles.helpMenuTabList}>
                <Link to="/oss/projects"><OssMenuTab tabIcon={<CardHeading/>} tabText="Projects" /></Link>
                <Link to="/oss/weekly-report"><OssMenuTab tabIcon={<ListOl/>} tabText="Weekly Report" /></Link>
                <Link to="/oss/daily-report"><OssMenuTab tabIcon={<CardChecklist/>} tabText="Daily Report" /></Link>
            </div>
            <div className={styles.helpMenuTabList}>
                <Link to="/oss/timesheets"><OssMenuTab tabIcon={<Table/>} tabText="Timesheet Builder" /></Link>
            </div>
        </div>
    );
};

export default RavMenuTabs;