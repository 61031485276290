import React from 'react';
import styles from '../../styles/help-page/HelpMain.module.css';

const FAQCards = props => {   
    return(
        <div className={styles.FAQCards}>
            {props.children}
        </div>
    )
}

export default FAQCards;