import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemLink button href="#cc-add">
          <ListItemIcon>
          <span className="material-icons">
            house
          </span>
          </ListItemIcon>
          <ListItemText primary="Add a task" />
        </ListItemLink>
        <ListItemLink button href="#cc-view">
          <ListItemIcon>
            <span className="material-icons">view_list</span>
          </ListItemIcon>
          <ListItemText primary="View tasks" />
        </ListItemLink>
        <ListItemLink button href="#cc-quote">
          <ListItemIcon>
            <span className="material-icons">
              drafts
            </span>
          </ListItemIcon>
          <ListItemText primary="Issue a quote" />
        </ListItemLink>
        <ListItemLink button href="#cc-complete">
          <ListItemIcon>
            <span className="material-icons">
              done
            </span>
          </ListItemIcon>
          <ListItemText primary="Complete a task" />
        </ListItemLink>
        <ListItemLink button href="#cc-invoices">
          <ListItemIcon>
            <span className="material-icons">
              monetization_on
            </span>
          </ListItemIcon>
          <ListItemText primary="Manage invoices" />
        </ListItemLink>
        <ListItemLink button href="#cc-analytics">
          <ListItemIcon>
            <span className="material-icons">
              pie_chart
            </span>
          </ListItemIcon>
          <ListItemText primary="Analysis and data" />
        </ListItemLink>
        <ListItemLink button href="#cc-other">
          <ListItemIcon>
            <span className="material-icons">
              linear_scale
            </span>
          </ListItemIcon>
          <ListItemText primary="Other" />
        </ListItemLink>
      </List>
    </div>
  );
}
