import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

const DailyReportContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="login">Logging in</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Credentials</b>
                        </h3>
                        <p>
                            Your login and password should be provided to you by your employer at the beginning of your employment. Once your account is ready, you can log in using your e-mail address and password. Keep these credentials safe and do not share them with anyone - they should remain confidential.
                        </p>
                    </div>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Log in to your account</b>
                        </h3>
                        <p>
                            Navigate to <a href="https://onsiteselfie.com/login"><b>onsiteselfie.com/login</b></a> to log in to your account. Enter your e-mail address in the Email field and your Password in the Password field. Press the red "Log me in!" button to initiate the logging in process. You should get access to your account momentarily. Once you are logged in, you should be able to see at least one main "Take photo now" button (or more depending on your organisation) as well as a navigation bar on the top.
                        </p>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="location">Location</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Allow location</b>
                        </h3>
                        <p>
                            When you log in the first time on a new device, you should be asked by the application to allow the use of your GPS location. You should press <b>Allow</b>, otherwise the application will not work correctly and will not be able to log your location to the system.
                        </p>
                        <p>
                            If you never granted location privileges to your mobile browser, you may be asked to allow your browser access to your phone's location. You should grant this privilege to your browser as well. Once this is all set up, you are ready to take your selfie.
                        </p>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="selfie">Selfie</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Take a selfie</b>
                        </h3>
                        <p>
                            To take your selfie, press the "Take photo now" on the main dashboard after logging in. The system automatically recognises if you are trying to clock in (the first log of the day) or clock out (the second log of the day). Your device's camera should open in an instant - after taking a selfie confirm by pressing OK.
                        </p>
                        <p>
                            You can now preview your selfie - don't worry if the picture is rotated, the orientation will be adjusted automatically. Press "Upload photo" to initiate the clocking in/out process - the countdown starts at 3 minutes, however usually the upload of the photo takes no more than a few seconds.
                        </p>
                        <p>
                            After the successful upload, you should be prompted with a confirmation screen - green tick and a success message. When you see that, your log has already been saved to the system. You can now view it in Logs.
                        </p>
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="logs">Your logs</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>View your logs</b>
                        </h3>
                        <p>
                            Press "My logs" in the navigation bar on the top of the screen. You should immediately see your most recent logs (clock ins and outs) for each day that you worked on. You can view the exact time that the log was recorded at. If there is an issue with the record - e.g. you clocked in late or the application malfunctioned due to poor network connection - you can leave a comment to your log.
                        </p>
                    </div>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Leave a comment</b>
                        </h3>
                        <p>
                            Press the small pencil icon beside a log to send a message to the administrator of the system in your organisation. Write your comment and press the "Submit" button. Once the note is recorded, press OK and go back to your logs. Your comment has been sent to the management team automatically.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default DailyReportContent;