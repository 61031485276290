import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ravVouchImg1 from "../../../../media/help-section/rav/help-rav-v-1.png";
import ravVouchImg2 from "../../../../media/help-section/rav/help-rav-v-2.png";
import ravVouchImg3 from "../../../../media/help-section/rav/help-rav-v-3.png";
import ravVouchDay from '../../../../media/help-section/rav/voucher/voucher-day-work.jpg';

const VoucherScrollField = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="vouchers-adding">Adding Vouchers</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Adding a new Voucher</b>
                        </h3>
                        <p>
                            In order to <b>add</b> a new voucher, please proceed to the "Add a Voucher" section under the "Vouchers" section. A screen displayed on the screenshot will appear:
                        </p>
                        <img alt="screen1" src={ravVouchImg1} className={styles.helpImgSm} />
                        <p>
                            Please fill in all the input fields with the details regarding your Voucher:
                        </p>
                        <ul>
                            <li>Subcontracor from your sites / other sites;</li>
                            <li>Site/Phase;</li>
                            <li>Week Commencing (choose Saturday).</li>
                        </ul>
                        <img alt="screen2" src={ravVouchImg2} />
                        <p>
                            If you want to add a <b>daywork</b>, press the <b>Switch Button</b>. You will be able to enter the number of hours of the daywork. The wage and the total value will be displayed. If you spot a mistake in the hourly rate value, please press the "Inform Office" button. An automatic e-mail will then be sent on the office mail.
                        </p>
                        <img alt="screen2" src={ravVouchDay} className={styles.helpImgMd} ></img>
                        <p>
                            Afterwards, please press the <b>Find</b> button. A list of relevant Quality Assessment will be displayed. You can choose which ones to add to the Voucher.
                        </p>
                        <p>
                            After you press the <b>Submit</b> button, a pop-up window with a summary of all the values you had entered/chose will appear. You can also add any <b>additional comments</b>. If the details are correct, please press <b>Proceed</b>. Voucher will be added to the system.
                        </p>
                    </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="vouchers-office">Office Side - Vouchers Management</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Management of Vouchers from the Office Side</b>
                        </h3>
                        <p>
                            To access the funcionality of the Vouchers Management, please press on the "Manage Vouchers" icon under the Vouchers section. The table displaying a list of vouchers will appear. In the filtering part, you can choose the week commencing to access vouchers from a given date range. Alternatively, you can switch the button above the table and filter for the advance filtering options and browse the vouchers by the status (Approved/To be edited/Pending/Declined) and Subcontractor's name.
                        </p>
                        <p>
                            In the table, you can see the voucher's status, preview it, or download the PDF file. To <b>send</b> the voucher, please press the green <b>Send</b> button. The e-mail with the voucher file attached will then be automatically sent to the subcontractor.
                        </p>
                    </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="vouchers-manager">Manager Side - Vouchers Management</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Management of Vouchers from the Manager Side</b>
                        </h3>
                        <p>
                            In order to access the management console of your previously added vouchers, please press the "Manage Vouchers" icon under the "Vouchers" section. In the table, a list of vouchers which you had previously added will be displayed.
                        </p>
                        <p>
                            You can <b>filter</b> your vouchers by their status (all/approved/to be edited/pending/declined) and subcontractor's name. Alternatively, you can switch the button above the table from "Basic" to "Calendar" and <b>filter your vouchers by date</b>. You can also reset your searching options.<br></br><br></br>
                            In the table, you will be able to see the status of a given voucher, check if it had been sent by the office, preview, delete it (if it had not been approved/sent yet), or download a corresponding file.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.instructionSectionTxtArea}>
                <div className={styles.instructionSectionTxt}>
                    <h3>
                        &#8226; <b>Vouchers from your site</b>
                    </h3>
                    <p>
                        If you are permitted an option to preview all the vouchers which had been added from your sites, you can access this functionality by pressing the "Site Vouchers" icon under the "Vouchers" section. The date range of displayed entries is placed next to the calendar field. By using calendar, you can <b>filter the vouchers.</b>
                    </p>
                    <p>
                        In the table, you will see all the vouchers from your sites. To preview them, press the "Preview" button of a corresponding table row. To download a file, press the PDF icon.
                    </p>
                    <p>
                        There is also a set of actions available:
                    </p>
                    <ul>
                        <li><b>Approve</b> - mark the voucher as approved;</li>
                        <li><b>Decline</b> - press this button to decline the voucher;</li>
                        <li><b>Enquiry</b> - press this button to request some changes in the voucher.</li>
                    </ul>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="vouchers-admin">Administrator Side - Vouchers Management</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Management of Vouchers from the Administrator Side</b>
                        </h3>
                        <p>
                            To access the funcionality of the Vouchers Management, please press on the "Vouchers" icon under the Vouchers section. The table displaying a list of vouchers will appear. In the filtering part, you can choose the week commencing to access vouchers from a given date range. Alternatively, you can switch the button above the table and filter for the advance filtering options and browse the vouchers by the status (Approved/To be edited/Pending/Declined) and Subcontractor's name. 
                        </p>
                        <p>
                            In the table, you can see the the details concerning a given voucher, preview it, or download the PDF file. The list of available functionalities include:
                        </p>
                        <ul>
                            <li><b>Approve voucher</b> - by choosing this option, you will mark the voucher as approved and an option to sent this voucher will become unlocked for the office;</li>
                            <li><b>Request changes</b> - Once this option is chosen, a pop-up box with a message field will apear. In there, you can enter any information and instructions about requested changes. <br />
                            <img alt="screen3" src={ravVouchImg3} /> <br />
                            </li>
                            <li><b>Decline voucher</b> - If the voucher is incorrect and you wish to decline it, please press the red "Decline" button.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default VoucherScrollField;