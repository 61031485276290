import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Daily Report
import DailyReportScrollList from './DailyReport/DailyReportScrollList';
import DailyReportContent from './DailyReport/DailyReportContent';

const DailyReportPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="daily-report"
                title="Daily Report"
                video={<HelpVideo link="https://www.youtube.com/embed/JE-op5dUEfs" title="OSS - Daily Reports Tutorial" />}
                stepper={""}
            >
                <ComponentHelpDetails 
                    scrollList={<DailyReportScrollList />} 
                >
                <InstructionScrollField 
                    title="Daily Report"
                >
                    <DailyReportContent />
                </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default DailyReportPage;
