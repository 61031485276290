import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

const UsersPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-users"
                title="Managing Users"
                video={<HelpVideo link="https://www.youtube.com/embed/CxdSoGNk7jY" title="RAV - Users Tutorial" />}
            >
                
            </SliderWithVideo>
        </div>
    );
};

export default UsersPage;
