import React, {useEffect} from 'react';
import ScrollToTop from 'react-router-scroll-top';
import './App.css';
import MainNavigation from './components/sidebar/MainNavigation';

import HelpPage from './components/help-page/HelpPage';
import RAVHelp from './components/help-page/RavHelp/RavHelp';
import PriceWorksPage from './components/help-page/RavHelp/PriceWorksPage';
import QaPage from './components/help-page/RavHelp/QaPage';
import VouchersPage from './components/help-page/RavHelp/VouchersPage';
import DocumentsPage from './components/help-page/RavHelp/DocumentsPage';
import RAVUsersPage from './components/help-page/RavHelp/UsersPage';
import RAVProjects from './components/help-page/RavHelp/ProjectsPage';
import RAVSubcontractors from './components/help-page/RavHelp/SubconsPage';
import RAVCCPage from './components/help-page/RavHelp/CCPage';

import OssFAQ from './components/help-page/OssHelp/OssFAQ';
import OssHelp from './components/help-page/OssHelp/OssHelp';
import ProjectsPage from './components/help-page/OssHelp/ProjectsPage';
import UsersPage from './components/help-page/OssHelp/UsersPage';
import DailyReportPage from './components/help-page/OssHelp/DailyReportPage';
import ClockInPage from './components/help-page/OssHelp/ClockInPage';
import WeeklyReportPage from './components/help-page/OssHelp/WeeklyReportPage';
import TimesheetBuilderPage from './components/help-page/OssHelp/TimesheetBuilderPage';

import HubHelp from './components/help-page/HubHelp/HubHelp';
import AdministrationPage from './components/help-page/HubHelp/AdministrationPage';
import ApproveSM from './components/help-page/HubHelp/ApproveSM';
import ApproveAT from './components/help-page/HubHelp/ApproveAT';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

const App = () => {
  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "bf569c34-0598-449e-b911-b7c574486a01";

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
   });

    return (
        <Router>
          <ScrollToTop>
            <Switch>
              <Route path="/oss" exact><OssHelp /></Route>
              <Route path="/oss/faq" exact><OssFAQ /></Route>
              <Route path="/oss/clocking" exact><ClockInPage /></Route>
              <Route path="/oss/projects" exact><ProjectsPage /></Route>
              <Route path="/oss/users" exact><UsersPage /></Route>
              <Route path="/oss/daily-report" exact><DailyReportPage /></Route>
              <Route path="/oss/weekly-report" exact><WeeklyReportPage /></Route>
              <Route path="/oss/timesheets" exact><TimesheetBuilderPage /></Route>
              <Route path="/hub" exact><HelpPage /></Route>
              <Route path="/rav" exact><RAVHelp /></Route>
              <Route path="/rav/subcontractors" exact><RAVSubcontractors /></Route>
              <Route path="/rav/projects" exact><RAVProjects /></Route>
              <Route path="/rav/price-works" exact><PriceWorksPage /></Route>
              <Route path="/rav/qa" exact><QaPage /></Route>
              <Route path="/rav/cc" exact><RAVCCPage /></Route>
              <Route path="/rav/users" exact><RAVUsersPage /></Route>
              <Route path="/rav/vouchers" exact><VouchersPage /></Route>
              <Route path="/rav/documents" exact><DocumentsPage /></Route>
              <Route path="/hub/help" exact><HubHelp /></Route>
              <Route path="/hub/admin" exact><AdministrationPage /></Route>
              <Route path="/hub/approve/sm" exact><ApproveSM /></Route>
              <Route path="/hub/approve/at" exact><ApproveAT /></Route>
              <Route path="/" exact><HelpPage /></Route>
              <Redirect to="/" />
            </Switch>
          </ScrollToTop>
        </Router>
    );
}

export default App;
