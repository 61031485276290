import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Vouchers
import VoucherScrollList from './Vouchers/VoucherScrollList';
import VoucherContent from './Vouchers/VoucherContent';
import VouchersIssuingStepper from './Vouchers/VouchersIssuingStepper';

const VouchersPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-vouchers"
                title="Issuing Payment Vouchers"
                video={<HelpVideo link="https://www.youtube.com/embed/bLnGE-0a1kY" title="RAV - Vouchers Tutorial" />}
                stepper={<VouchersIssuingStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<VoucherScrollList />} 
                >
                    <InstructionScrollField 
                        title="Instructions for Vouchers"
                    >
                        <VoucherContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default VouchersPage;
