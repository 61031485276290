import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for CC
import VoucherScrollList from './CC/CCScrollList';
import VoucherContent from './CC/CCContent';
import VouchersIssuingStepper from './CC/CCStepper';

const CCPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-customer-care"
                title="Managing Customer Care"
                video={<HelpVideo link="https://www.youtube.com/embed/9ersU_X2yk8" title="RAV - Customer Care Tutorial" />}
                stepper={<VouchersIssuingStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<VoucherScrollList />} 
                >
                    <InstructionScrollField 
                        title="Instructions for Customer Care"
                    >
                        <VoucherContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default CCPage;
