import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images 
import ravCCAdd from '../../../../media/help-section/rav/cc/cc-add.jpg';
import ravCCAddForm from '../../../../media/help-section/rav/cc/cc-add-form.jpg';
import ravCCViewTasks from '../../../../media/help-section/rav/cc/cc-view-tasks.jpg';
import ravCCTaskDescription from '../../../../media/help-section/rav/cc/cc-task-description.jpg';
import ravCCTaskSchedule from '../../../../media/help-section/rav/cc/cc-task-schedule.jpg';
import ravCCTaskComment from '../../../../media/help-section/rav/cc/cc-task-comment.jpg';

const CCContent = () => {
    return(
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="cc-add">Adding a task</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <p>To add a new task, you need to navigate to Add a New Task page in the Manage Customer Care section of RAV Dashboard.</p>
                        <img src={ravCCAdd} alt="Add a Task" className={styles.helpImgSm} />
                        <p>You will then be shown a form to add a new customer care task. File name is the unique name of the document sent by the client (usually starting with CC). If you are unsure of the number, enter NA instead. The rest of the details that need to be filled in are usually available in the jobsheet received from the client. You need to attach the jobsheet in the PDF format. Finally, assign the task to the correct project. To add the task, press Add.</p>
                        <img src={ravCCAddForm} alt="Add a Task Form" />
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="cc-view">Managing tasks</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Viewing tasks</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>
                            Go to View Tasks to gain access to the list of all active, incomplete customer care tasks. By default all the tasks are displayed in the table, ordered from the oldest added to the system.
                        </p>
                        <p>
                            There are a few <b>filters</b> that you can use to retrieve tasks of your interest. By using the <b>Site filter</b>, you can show tasks from a specific project only. If you type in the task number or the task plot to respective filter fields and press Enter, you will be able to find a <b>specific task</b> or <b>tasks for the same plot</b>. Finally, by setting a date in the <b>Appointment filter</b>, you can display all tasks that have an appointment scheduled for this specific date. Press the <b>arrow button</b> in the filters section to reset the filters.
                        </p>
                        <img src={ravCCViewTasks} alt="View Tasks" />
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Functionalities in View Tasks</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>Looking at a row with a customer task from left to right, these are the functionalities available:</p>
                        <p>A. Press on the bolded task number to reveal a description in a small pop-up box. To close the pop-up, press the small X in the top right corner.</p>
                        <img src={ravCCTaskDescription} alt="Task Description" className={styles.helpImgMd} />
                        <p>B. Press on the Calendar icon in the Visit column to schedule a new appointment. Select the date and time and press Schedule. An automated email will be sent to the CC team informing them that you've scheduled an appointment. Once scheduled, instead of an icon, the date and time will be displayed. You can press them again to reschedule an appointment or cancel it.</p>
                        <img src={ravCCTaskSchedule} alt="Task Schedule" className={styles.helpImgMd} />
                        <p>C. Press the PDF icon in the PDF column to download the jobsheet.</p>
                        <p>D. Press the green tick icon in the Done column to proceed to issue a report and invoice.</p>
                        <p>E. Press the gray Pencil button to add a new comment to the task. A popup box will appear with the current comment or an empty field. To save the comment, press Submit. If a comment is already in the system, you can press on the text for the same popup box to appear so you can edit the comment or delete it.</p>
                        <img src={ravCCTaskComment} alt="Task Comment" />
                        <p>F. Press the Update button in the Details column to edit the task. You are able to alter any detail, including the jobsheet.</p>
                        <p>G. Press the Quote button in the Quote column to proceed to the form to issue a quotation for this specific task.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CCContent;