import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "../../../../styles/help-page/SystemHelp.module.css";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
    },
    button: {
      marginTop: "2rem",
      marginRight: theme.spacing(1),
    },
    instructions: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      margin: '0 auto',
      textAlign: 'justify',
    },
  }));
  
  function getSteps() {
    return ['Add', 'Price Works', 'Manager', 'Office', 'Admin'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'To add a new payment voucher, go to Add a Voucher in the Manager Vouchers section of RAV Dashboard. You need to select a subcontractor from the list, choose for which project the voucher is issued and select the date which commences the week that you issue the voucher for (e.g. if you are issuing a voucher for the week from 7th to 13th of January, choose 7th of January as the date of the voucher). If the operative is going to be paid for some working hours as well, not only price work, toggle the Day Work button and fill in the hours. If the hourly rate is wrong, press the Inform Office button to notify the office, and proceed as normally. The office will adjust the voucher manually. Press Find to search for available QAs with price works.';
      case 1:
        return 'In order to include price works in your vouchers, you first need to add price works to the system (as described in the Price Works section) and then issue a Quality Assessment for this price work. After pressing Find in Add a New Voucher form, you will be shown a table with QAs that have been issued for price works and that have not yet been included in any voucher. A single QA can be added to only one voucher. In the second table are pressure testing QAs - when you add them to the voucher, the deduction for the price work will be returned. After selecting all relevant QAs, you need to press Generate. Your voucher should be ready.';
      case 2:
        return 'To view the voucher that you have added, go to Your Vouchers. To find your vouchers for the specific week, change the toggle switch from Basic to Date and enter the day commencing the week you wish to view. You can also look for all the vouchers for a specific subcontractor and filter them by their status (pending, approved, declined). If the voucher is declined, you need to delete it from the system to release the QAs that were matched with it. You can now add a new, corrected voucher with these QAs.';
      case 3:
        return 'The Office can view all the vouchers issued for a given week. They can preview the status of the voucher - whether it is approved, pending or declined. If the voucher is approved, they can press the Send button to send an automated email with the PDF voucher attached.';
      case 4: 
        return 'Administrators can preview all the vouchers issued for a given week as well as change their status. To view the details of the voucher, including the QAs and price works associated with it, you need to press the Preview button. To approve the voucher, press the green Approve button. If the voucher is incorrect or invalid, press the yellow Decline button. Finally, to inform the issuer that there are some problems with their voucher that need to be resolved, press the blue Request button, enter your message and press Send. Whenever you change the status of a voucher, a small arrow will appear - when pressed, it reverses the activity (e.g. unapproves the voucher).';
      default:
        return 'Unknown step';
    }
  }

const VouchersIssuingStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
  
    const isStepOptional = (step) => {
      return step === 10;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <div className={classes.root}>
        <Stepper className={styles.stepperScroll} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                Congratulations! You have learnt how to issue payment vouchers.
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
  
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default VouchersIssuingStepper;