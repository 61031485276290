import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  
  export default function SimpleList() {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItemLink button href="#projects-adding">
            <ListItemIcon>
              <span className="material-icons">control_point</span>
            </ListItemIcon>
            <ListItemText primary="Adding projects" />
          </ListItemLink>
          <ListItemLink button href="#projects-list">
            <ListItemIcon>
              <span className="material-icons">
              list
              </span>
            </ListItemIcon>
            <ListItemText primary="Projects List" />
          </ListItemLink>
          <ListItemLink button href="#updating-projects">
            <ListItemIcon>
              <span className="material-icons">
              settings
              </span>
            </ListItemIcon>
            <ListItemText primary="Updating Projects" />
          </ListItemLink>
        </List>
      </div>
    );
  }
  