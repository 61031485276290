import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import styles from "../../../../styles/help-page/SystemHelp.module.css";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
    },
    button: {
      marginTop: "2rem",
      marginRight: theme.spacing(1),
    },
    instructions: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      margin: '0 auto',
      textAlign: 'justify',
    },
  }));
  
  function getSteps() {
    return ['Introduction', 'Manage tasks', 'Complete task', 'Manage invoices', 'Other options'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'The Customer Care module allows you to manage the customer care tasks, reporting and invoicing, all in one place for all of your projects. Using RAV, you can add new tasks and jobsheets to the system, assign them to your sites, schedule appointments (with automatic notification emails and reminders), issue a PDF report and invoice for each completed task, issue a quotation for a task, track the invoices sent and paid, issue credit notes and view customer care analytics.';
      case 1:
        return 'To add a new task to the system, navigate to Add a New Task in the Manage Customer Care section. You will need to fill in the details of the task, assign it to the correct project and upload the jobsheet. You can view all active tasks on the View Tasks page where you have an option to schedule or cancel an appointment, download a jobsheet, save a comment, issue a quote (by pressing the black Quote button) or complete the task (by pressing the green tick button in the Done column).';
      case 2:
        return 'After pressing the green tick in the respective row of CC Tasks table, you will be redirected to the Complete Task form where you can issue a report and an invoice for the customer care services for this specific task. You can select whether you want to include a default address of the client on the invoice or if you want to enter it manually. You can also specify the number of hours that you worked on the task and the hourly charge. If there is a quote associated with the task, only the quote total will be taken into account. If you do not want to charge for the task, mark it as Free of Charge (FOC), then the total will be zero and no invoice will be generated. Press the small + button to add another row with an issue that you want to describe. When you press Generate, all the documents will be issued automatically. The completed task will not be shown in View Tasks anymore.';
      case 3:
        return 'Customer care reports and invoices are shown in three different places, depending on their status. Invoices issued the most recently, that have not sent to the client just yet, can be found under Completed Tasks to Send. To view invoices that were already emailed to the client but are not paid by them yet are listed in Tasks Awaiting Payment. Finally, the invoices that were paid can go to the Archive of Tasks. This is also where any cancelled tasks are shown. The Credit Notes window allows you to issue a credit note for a specific CC invoice as well as view the existing ones.';
      case 4:
        return 'If you want to find a specific task, its description, date of the appointment, jobsheet, current status and the invoice with the report - if they have been issued - you can go to Browse Tasks, enter the task number and press Enter or Search. The Analysis page allows you to download spreadsheets with the details of tasks as well as invoices. Moreover, you can export CC invoices to a format compatible with Sage. Finally, there are a number of graphs with basic customer care analytics such as the number of tasks per site or the total value of tasks per month.';
      default:
        return 'Unknown step';
    }
  }

const PWStepper = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
  
    const isStepOptional = (step) => {
      return step === 10;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <div className={classes.root}>
        <Stepper className={styles.stepperScroll} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                Congratulation! You have learnt how to manage customer care.
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
  
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default PWStepper;