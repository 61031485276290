import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Timesheet Builder
import TimesheetBuilderScrollList from './TimesheetBuilder/TimesheetBuilderScrollList';
import TimesheetBuilderContent from './TimesheetBuilder/TimesheetBuilderContent';

const TimesheetBuilderPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="timesheet-builder"
                title="Timesheet Builder"
                video={<HelpVideo link="https://www.youtube.com/embed/OS2M4P28nNo" title="OSS - Timesheet Builder Tutorial" />}
                stepper={""}
            >
                <ComponentHelpDetails 
                    scrollList={<TimesheetBuilderScrollList />} 
                >
                    <InstructionScrollField 
                        title="Timesheet Builder"
                    >
                        <TimesheetBuilderContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default TimesheetBuilderPage;
