import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Quality Assessments


const QaPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-projects"
                title="Managing Projects"
                video={<HelpVideo link="https://www.youtube.com/embed/KF6cUQaLEGE" title="RAV - Projects Tutorial" />}
            >
                
            </SliderWithVideo>
        </div>
    );
};

export default QaPage;
