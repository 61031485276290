import React from 'react';

import styles from '../../styles/help-page/SystemHelp.module.css';

const SliderWithVideo = props => {
    return(
        <React.Fragment>
            <div className={styles.sliderWithVideo} id={props.idName}>
                <div>
                    <div className={styles.sliderWithVidTitle}>
                        <span>{props.title}</span>
                    </div>
                    {props.video}
                    <div className="centercontent">
                        {props.stepper}
                    </div>
                </div>
            </div>
            {props.children}
        </React.Fragment>
        
    );
};

export default SliderWithVideo;