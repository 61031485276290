import React from 'react';
import styles from '../../styles/help-page/HelpMain.module.css';
import { Link } from 'react-router-dom';

// Import of Images
import cmsIconLg from "../../media/help-section/main/cms-lg.png";
import ossIconLg from "../../media/help-section/main/oss-lg.png";
import ravIconLg from  "../../media/help-section/main/rav-lg.png";

const HelpMain = () => {   
    return(
        <div className={styles.helpSystems}>
            <Link to="/hub/help">
                <div className={styles.systemIconLarge}>
                    <img src={cmsIconLg} alt="cmsHubIcon" title="CMS Hub" />
                    <p>CMS Hub</p>
                </div>
            </Link>
            <Link to="/oss">
                <div className={styles.systemIconLarge}>
                    <img src={ossIconLg} alt="ossIcon" title="OSS" />
                    <p>On-site Selfie</p>
                </div>
            </Link>
            <Link to="/rav">
                <div className={styles.systemIconLarge}>
                    <img src={ravIconLg} alt="ravSystemIcon" title="RAV System" />
                    <p>RAV System</p>
                </div>
            </Link>
        </div>
    );
};

export default HelpMain;