import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images 
import ravPWDashboard from '../../../../media/help-section/rav/pw/pw-dashboard.jpg';
import ravPWAddUnit from '../../../../media/help-section/rav/pw/pw-add-unit.jpg';
import ravPWEditUnit from '../../../../media/help-section/rav/pw/pw-unit-edit.jpg';
import ravPWEditUnitForm from '../../../../media/help-section/rav/pw/pw-unit-edit-form.jpg';
import ravPWTemplatesDash from '../../../../media/help-section/rav/pw/pw-template-dash.jpg';
import ravPWTemplateAdd from '../../../../media/help-section/rav/pw/pw-template-add.jpg';
import ravPWTemplateEmptyUnit from '../../../../media/help-section/rav/pw/pw-template-empty-unit.jpg';
import ravPWTemplateUnits from '../../../../media/help-section/rav/pw/pw-template-units.jpg';
import ravPWTemplatesList from '../../../../media/help-section/rav/pw/pw-templates-list.jpg';
import ravPWTemplatesEdit from '../../../../media/help-section/rav/pw/pw-templates-edit.jpg';
import ravPWAddPW from '../../../../media/help-section/rav/pw/pw-add-pw.jpg';
import ravPWAddForm1 from '../../../../media/help-section/rav/pw/pw-add-form-1.jpg';
import ravPWAddForm2 from '../../../../media/help-section/rav/pw/pw-add-form-2.jpg';
import ravPWAddForm3 from '../../../../media/help-section/rav/pw/pw-add-form-3.jpg';
import ravPWAddForm4 from '../../../../media/help-section/rav/pw/pw-add-form-4.jpg';
import ravPWManage from '../../../../media/help-section/rav/pw/pw-manage.jpg';
import ravPWManageTable from '../../../../media/help-section/rav/pw/pw-manage-table.jpg';

const PWContent = () => {
    return(
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="pw-intro">Navigating to Price Works</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <p>
                            In order to access the functionalities of the management of Price Works, please find the <b>"Manage Price Works"</b> tab on the <b>RAV Dashboard</b> and press on it. All the core module features will then be toggled and ready to used. We will introduce all four of the main price work options in this tutorial. Please find a screenshot below for reference.
                        </p>
                        <img src={ravPWDashboard} alt="RAV PW Dashboard" />
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="pw-units">Managing payment units</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Adding a new unit</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>
                            Payment units are the building blocks of your price work templates. They are individual tasks or jobs that need to be conducted to finish the whole price work such as Uninstrut and Basket that make up the Bracketry price work. 
                        </p>
                        <p>
                            To add a new payment unit type or view and edit the existing ones, please navigate to Unit Payment Types, the last of four options from the RAV Dashboard. When you open the page, you will be able to see the form to add a new unit type, as well as a table with existing payment units.
                        </p>
                        <img src={ravPWAddUnit} alt="PW Add Unit" />
                        <p>
                            Adding a new unit is simple. Firstly, make sure that the unit you intend to add does not already exist. They are listed alphabetically in the table. To create a new payment unit for your price works, enter a meaningful name such as "Bath tub", the unit e.g. 1 bath or 1m, price per unit e.g. 5.50 (a decimal number greater than 0) and deduction in percentages (e.g. 20 for 20% deduction or 0, when no deductions are made). The last thing is to press the Add button. Ready!
                        </p>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Editing a payment unit</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>
                            To edit a payment unit that already exists, please press the Edit button in the table for the row of the unit that you need to alter.
                        </p>
                        <img src={ravPWEditUnit} alt="PW Edit Unit" />
                        <p>
                            After pressing the Edit button, you will be redirected to a new page with a simple edit form that contains the same fields that you had to fill in when adding the job. You can adjust the name of the task as well as the name of the single unit (e.g. 1 l/m instead of 1m), price per one unit and the deduction rate. After changing the details, press the green <b>Update</b> button.
                        </p>
                        <img src={ravPWEditUnitForm} alt="PW Edit Unit" />
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Disabling a payment unit</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>
                            If a payment unit is no longer in use or it has been added by an accident and cannot be edited, you can disable it so it will not be used in any new price work templates. It will, however, remain in the templates that are still in use.
                        </p>
                        <img src={ravPWEditUnit} alt="PW Edit Unit" />
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="pw-templates">Managing price work templates</div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Adding a price work template</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>
                            Price work templates are standardised and reusable sets of smaller tasks (payment units) which need to be conducted to fulfil a specific work. An example of a price work template could be Bathroom Pre-Plum which contains e.g. toilet pipework, bath pipework or shower mixer. 
                        </p>
                        <p>To add, view or edit price work templates. navigate to Price Work Types in the Manage Price Works sections:</p>
                        <img alt="PW Types" src={ravPWTemplatesDash} className={styles.helpImgSm} />
                        <p>On the top of the page is a form to add a price work template. The three main fields are price work name, description (which is optional) and the type (mechanical or electrical).</p>
                        <img alt="PW Template Add" src={ravPWTemplateAdd} /> 
                        <p>Each price work template need to have <b>at least one</b> payment unit, otherwise you will not be able to add it to the system. To add more than one payment unit to the template, press the blue "plus" button below the last unit.</p>
                        <img alt="PW Template Empty" src={ravPWTemplateEmptyUnit} /> 
                        <p>You can adjust the quantity of payment units, if they are fixed (e.g. if you always install 5 HIUs or 25 meters of pipeline). You can also leave it as 0 and adjust the quantities specifically when adding a price work to the system. This is just a template at this stage.</p>
                        <p>For each unit, you are able to see what the price and deductions are. These will be summed up for the whole template to give you an idea of how much price works generated with this template are worth.</p>
                        <img alt="PW Template Units" src={ravPWTemplateUnits} /> 
                        <p>To delete a unit, press the small "bin" icon in the row of the unit. To add the ready template to the system, press the green "Create" button.</p>
                    </div>
                </div>
                <div className={styles.instructionSectionTxtArea}>
                    <h3>&#8226; <b>Managing price work templates</b></h3>
                    <div className={styles.instructionSectionTxt}>
                        <p>To view your price work templates, scroll down to reach the table under the form. In the table are all templates listed alphabetically. Each of them can either be edited or disabled by pressing the respective buttons in the correct row.</p>
                        <img alt="PW Templates List" src={ravPWTemplatesList} />
                        <p>Once a template is disabled, no one will be able to use it to create new price works anymore. The price works that are already in the system will not be affected.</p>
                        <p>When you press Edit, you will be redirected to a new page where you can alter any detail of the price work template, including the units, quantities or name and description. You can delete any payment unit by pressing the black "bin" icon in the respective row. To add a new payment unit, press the blue "plus" button, as previously. To save the changes, press "Save".</p>
                        <img alt="PW Templates Edit" src={ravPWTemplatesEdit} />
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="pw-add">Adding a price work</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <p>Price works are essential to calculate the amount of money that has to be paid to a subcontractor for a specific work conducted by them on site. Without price works in the system, you will not be able to create payment vouchers (unless an operative is paid on an hourly basis only). Each price work requires a Quality Assessment to be issued for the price work to be marked as completed. Only then will you be able to include this amount of money for this specific work to the voucher.</p>
                        <p>To add a new price work, navigate to "Add a Price Work" in the Manage Price Works section in RAV Dashboard. After pressing the button, you will be redirected to the form to add a new price work.</p>
                        <img alt="PW Add" src={ravPWAddPW} className={styles.helpImgSm} />
                        <p>Firstly, you need to <b>select the subcontractor</b> to which you are assigning this price work. By default you will see subcontractors that are working on the sites you are managing. Should you need to find another operative, press the black switch "Find others" to <b>display all</b> subcontractors in the system. You can then select at which project this price work will be conducted. These are only the projects you are supervising.</p>
                        <img alt="PW Add Form" src={ravPWAddForm1} />
                        <p>After that, you need to <b>select the price work template</b> that you are going to use to create this price work. To do so, choose one from the drop-down menu in Price Work Category. You can also adjust the date of the price work, description and the type (electrical or mechanical).</p>
                        <img alt="PW Add Form" src={ravPWAddForm4} />
                        <p>When you select the template, all payment units will automatically appear in the form. You are then able to adjust the price per unit - however this is discouraged as the company aims at standardising the prices and quantities of these units. The total for this price work will be calculated by the system, taking into consideration the deduction.</p>
                        <img alt="PW Add Form" src={ravPWAddForm2} />
                        <p>If you want to schedule more than one such price work for the same subcontractor on the same site, you can change the quantity of price works to more than one. If you set it to 5, five identical price works will be added to the system. Each of them will require a unique Quality Assessment.</p>
                        <p>Finally, at the bottom is a total payable amount per one price work, total deductions and the grand total for all price works if you are adding more than one at the same time. To <b>save the price work(s)</b>, press "Add".</p>
                        <img alt="PW Add Form" src={ravPWAddForm3} />
                    </div>
                </div>
            </div>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="pw-manage">Managing price works</div>
                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <p>To manage your active price works, go to the "Manage Price Works" page in the "Manage Price Works" section of the RAV Dashboard.</p>
                        <img alt="PW Manage" src={ravPWManage} className={styles.helpImgSm} />
                        <p>In the Manage Price Works panel, you can view your price works, issue a Quality Assessment from them, edit them and delete them, by pressing the respective buttons in selected rows.</p>
                        <p>You can filter your price works by title, by date issued or by a subcontractor using fields just above the table. The filters work independently so you can only use on at a time.</p>
                        <img alt="PW Manage" src={ravPWManageTable} />
                        <p>To delete a price work, you can press the red "Delete" button. This will imemediately delete the selected price work. This process is <b>irreversible</b>.</p>
                        <p>To edit a price work, you can press the gray "Edit" button. You will be redirected to the edit page where you can adjust the basic details such as the title, description or the date of the price work. Other details cannot be edited - if you made a mistake in the price work category or unit prices, you need to delete the faulty price work and add a new one correctly.</p>
                        <p>To issue a quality assessment for the specific price work, you can press the green tick button in the QA column. This will take you directly to "Add a Quality Assessment" page and will fill in all the core details of the price work for you. Once you have issued a QA for this price work, the price work will no longer appear in your table.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PWContent;