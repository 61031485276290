import React from 'react';
import styles from '../../styles/help-page/SystemHelp.module.css';

const HelpVideo = props => {
    return (
        <div className={styles.tutorialVideo}>
            <iframe title={props.title} width="560" height="315" src={props.link} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
};

export default HelpVideo;