import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Weekly Report
import ClockInScrollList from './ClockIn/ClockInScrollList';
import ClockInContent from './ClockIn/ClockInContent';

const DailyReportPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="clocking-in"
                title="Clocking in and out"
                video={<HelpVideo link="https://www.youtube.com/embed/6KJL5CVg2qY" title="OSS - Clocking In Tutorial" />}
                stepper={""}
            >
                <ComponentHelpDetails 
                    scrollList={<ClockInScrollList />} 
                >
                    <InstructionScrollField 
                        title="Clocking in and out"
                    >
                        <ClockInContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default DailyReportPage;
