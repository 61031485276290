import React from 'react';

// Common reusable components
import HelpVideo from '../HelpVideo';
import ComponentHelpDetails from '../ComponentHelpDetails';
import SliderWithVideo from '../SliderWithVideo';
import InstructionScrollField from '../InstructionScrollField';

// Components for Quality Assessments
import QAStepper from './QA/QAStepper';
import QAScrollList from './QA/QAScrollList';
import QAContent from './QA/QAContent';

const QaPage = () => {
    return(
        <div className="">
            <SliderWithVideo 
                idName="rav-quality-assessments"
                title="Issuing Quality Assessments"
                video={<HelpVideo link="https://www.youtube.com/embed/bBDOE89HmNE" title="RAV - Quality Assessment Tutorial" />}
                stepper={<QAStepper />}
            >
                <ComponentHelpDetails 
                    scrollList={<QAScrollList />} 
                >
                    <InstructionScrollField 
                        title="Instructions for Quality Assessments"
                    >
                        <QAContent />
                    </InstructionScrollField>
                </ComponentHelpDetails>
            </SliderWithVideo>
        </div>
    );
};

export default QaPage;
