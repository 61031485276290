import React from 'react';
import styles from '../../../../styles/help-page/SystemHelp.module.css';

// Import of images
import ossImg1 from "../../../../media/help-section/oss/ossscreen1.png"
import ossImg2 from "../../../../media/help-section/oss/ossscreen2.png"
import ossImg3 from "../../../../media/help-section/oss/ossscreen3.png"

const ProjectsContent = () => {
    return (
        <React.Fragment>
            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="projects-adding">Adding Projects</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Adding a new Project</b>
                        </h3>

                            In order to <b>add</b> a new project, please proceed to the "Projects" tab, accessible from the main navigation bar. From there, choose the "Add Project" option. The following form will then be displayed:
                            <br></br><br></br>
                            <img alt="screen1" src={ossImg1}></img>
                            <br></br><br></br>
                            Please fill in all the input fields with the details regarding the Project:
                            <ul>
                            <li>Label (name that will be displayed in the timesheet);</li>
                            <li>Location;</li>
                            <li>Description;</li>
                            <li>Default hours of operations.</li>
                        </ul>
                        <br></br><br></br>
                            In order to <strong>add the project's location</strong>, press on the <strong>map icon</strong> located next to the <strong>location</strong> input field. A pop-up box with embedded map of London should then appear. Press on the approximate area of the location of your project in order to zoom the map in. Then, simply click on the chosen part of the map in order to automatically determine coordinates and pin the location. To further navigate through the map, use the buttons on the right, or ctrl key + scroll on the mouse. You can also drag the map using the mouse.<br></br> 
                            To finish, press the "x" button. Your setting will be saved once you submit the new project. Now, whenever an employee clocks in or out within a given radius from the pinned location, the application will detect it automatically.  <br></br>
                            Alternatively, you can enter the location manually. In order to do that, just enter the coordinates of your location into the input box. 
                            <br></br><br></br>
                            <img alt="screen2" src={ossImg2}></img>
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="projects-list">Projects List</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Browsing your projects</b>
                        </h3>

                            You can easily browse and review your previously added projects by accessing the "Projects" tab from the main menu. In the Projects List, you can see the label of each project, as well as the corresponding location. In order to preview the location on the embedded map, press on the map icon of the chosen project. If you wish to view more details about the given project, or update it, click on the project's label.
                        </div>
                </div>
            </div>

            <div className="instructionSection">
                <div className={styles.instructionSectionTitle} id="updating-projects">Updating projects</div>

                <div className={styles.instructionSectionTxtArea}>
                    <div className={styles.instructionSectionTxt}>
                        <h3>
                            &#8226; <b>Updating the details of your project</b>
                        </h3>

                            In order to access the details of the given project, first choose it from the Projects List. The form with the relevant input fields will then be displayed, as depicted on the screenshot below:
                            <br></br><br></br>
                            <img alt="screen3" src={ossImg3}></img>
                            <br></br><br></br>
                            From this view, you can adjust any chosen details of your project. You can also mark it as unactive, or delete it completely from the system. Once you make all the changes you wish to apply, press the "Update project" button.
                        </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default ProjectsContent;